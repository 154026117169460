import React from 'react'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import WantToKnowMore from './sections/want-to-know-more/_index'

function SpreadsheetMei () {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <WantToKnowMore />
      </Layout>
    </Wrapper>
  )
}

export default SpreadsheetMei
