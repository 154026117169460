import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'

type InterDayProps = {
  accept: boolean;
  setAccept: Function;
  name: string;
  styles?: string;
}
function InterPag ({ accept, setAccept, name, styles }: InterDayProps) {
  const [ sendDatalayerEvent ] = useDataLayer()

  function handleCheck () {
    setAccept(!accept)
  }

  return (
    <S.Checkbox>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type='checkbox'
        className='form-input-check'
        onChange={() => handleCheck()}
        checked={accept}
      />
      <label className={`form-label-check ${styles}`} htmlFor={name}>
        <div className='w-100'>
          <span>Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a</span>
          <a
            className='fw-700'
            title='Acessar Regras e Condições'
            href='https://inter.co/politica-de-privacidade/privacidade/'
            target='_blank' rel='noreferrer'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'Política de Privacidade',
                section_name: 'Ficou animado pra curtir esse evento lado a lado com a gente?',
                redirect_url: 'https://inter.co/politica-de-privacidade/privacidade/',
              })
            }}
          > Política de Privacidade
          </a>
        </div>
      </label>
    </S.Checkbox>
  )
}

InterPag.defaultProps = {
  name: 'aceite',
  styles: 'fs-12 lh-15',
}

export default InterPag
